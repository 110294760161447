/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
.bgcl-lightblue{
  background-color: rgb(237, 246, 255);
}


/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

:root {

   /* cricknotch blue */

  /* --primary-color: #2244db;
  --secondary-color: #203cb6; */

   /* blue 1 */

   /* --primary-color: #202657; 
   --secondary-color: #202657;  */

   /* --primary-color: #2c96a0; 
   --secondary-color: #237880;  */

   --primary-color: #015b7e; 
   --secondary-color: #023c53; 
   --ternary-color : #015b7e57;

   /* blue 2 */

   /* --primary-color: #484f86; 
   --secondary-color: #202657;  
   --ternary-color : #015b7e57;*/
   /* #8fb8ca85 */

    /* sports ruler blue */

  /* --primary-color: #00c0ef; 
  --secondary-color: #0177b5; */

   /* sports ruler blue shuffle*/

   /* --primary-color: #0177b5; 
   --secondary-color: #00c0ef; */

   /* score card pervious blue*/

   /* --primary-color: #1983bc; 
   --secondary-color: #8ac0dd; */

    /* score card pervious blue shuffle*/

    /* --primary-color: #8ac0dd;
    --secondary-color: #1983bc;  */
    
}

/*Assistant FONT*/

@font-face {
  font-family: 'Assist-Light';
  src: url('/assets/fonts/Assistant-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Assist-Regular';
  src: url('/assets/fonts/Assistant-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Assist-Medium';
  src: url('/assets/fonts/Assistant-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Assist-SemiBold';
  src: url('/assets/fonts/Assistant-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Assist-Bold';
  src: url('/assets/fonts/Assistant-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/*Assistant FONT*/
